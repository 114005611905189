import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import LecturerLiveSessionsController, {
  Props, InitialValues, Course
} from "./LecturerLiveSessionsController";
import { Box, Button, Grid, InputLabel, Paper, Switch, Typography, withStyles } from "@material-ui/core";
import { ErrorMessage, Field, Formik, FormikProps } from "formik";
import * as Yup from 'yup';
import { downimage } from "./assets";


const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    }
  }
});

const validationSchema = Yup.object({
  date:Yup.date()
  .nullable()
  .required('Date is required.')
  .min(new Date().toISOString().split('T')[0], 'Date should be in the future.'),
  startTime: Yup.string().required('Start time is required.'),
  subject: Yup.string().required('Subject is required.'),
  livelocation: Yup.string().required('Location is required.'),
  courseImage: Yup.mixed().required('Image is required'),
  course_id:Yup.string().required('Course is required.'),
  endTime: Yup.string()
      .required('End time is required.')
      .test('is-greater', 'End time must be greater than start time.', function (value) {
        const { startTime } = this.parent;
        return !startTime || !value || value > startTime;
  }),
});

// Customizable Area End


export default class LecturerLiveSessionsCreate extends LecturerLiveSessionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          title={"Schedule Live Sessions"}
        >
          <MainContainer>
            <Paper elevation={0} className="paper-container">
              <Formik
                onSubmit={(values) => {
                  this.onSubmit(values)
                }}
                validationSchema={validationSchema}
                initialValues={{
                  courseImage: null,
                  subject: "",
                  livelocation:"",
                  date:'',
                  startTime: "",
                  endTime: "",
                  showtostudent:false,
                  course_id:""
                }}
              >
                {({ handleSubmit, setFieldValue, values}: FormikProps<InitialValues>) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box className="container-main" data-test-id="container-main">
                        <Box className="marginbottom" data-test-id="marginbottom">
                          <Typography className="from-main-title" data-test-id="from-main-title">Schedule Session</Typography>
                          <Box className="from-first-container" data-test-id="from-first-containe">
                            <Grid container className="from-first-container-grid" data-test-id="from-first-container-grid" spacing={3}>
                              <Grid item>
                                <Box className="image-container-main" data-test-id="image-container-main">
                                  <Box className="image-container" data-test-id="image-container">
                                    {values.courseImage ? (
                                      <img src={URL.createObjectURL(values.courseImage as Blob)} />
                                    ) : (
                                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M8.33334 35C7.41667 35 6.63167 34.6733 5.97834 34.02C5.325 33.3667 4.99889 32.5822 5 31.6667V8.33334C5 7.41667 5.32667 6.63167 5.98 5.97834C6.63334 5.325 7.41778 4.99889 8.33334 5H31.6667C32.5833 5 33.3683 5.32667 34.0217 5.98C34.675 6.63334 35.0011 7.41778 35 8.33334V31.6667C35 32.5833 34.6733 33.3683 34.02 34.0217C33.3667 34.675 32.5822 35.0011 31.6667 35H8.33334ZM10 28.3333H30L23.75 20L18.75 26.6667L15 21.6667L10 28.3333Z" fill="white" />
                                      </svg>
                                    )}
                                  </Box>
                                  <Box>
                                    <Typography className="from-main-title-first" data-test-id="from-main-title-first">{values.subject ? values.subject : "Live Session"}</Typography>
                                    <Typography className="from-main-title-second" data-test-id="from-main-title-seconds">By {this.state.profileInformation.first_name}</Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item>
                                <Box className="upload-icon-container" data-test-id="upload-icon-container">
                                  <input
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onFileChange(event, setFieldValue)}
                                    className="courseImage"
                                    accept="image/*"
                                    type="file"
                                    name="courseImage"
                                    id="courseImage"
                                    data-test-id="uploadField"
                                    title=""
                                  />
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8042 15.0208 15.4125 15.4125C15.0208 15.8042 14.55 16 14 16H2ZM7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7Z" fill="#37657F" />
                                  </svg>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <ErrorMessage name="courseImage" component="div" className="error-message" data-test-id="error-message" />
                        </Box>


                        <Box className="marginbottom" data-test-id="marginbottom">
                          <Typography className="from-main-title" data-test-id="from-main-title">Basic Information</Typography>
                          <Box className="from-first-container" data-test-id="from-first-container">
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <InputLabel className="main-input-label" data-test-id="Subject">Subject*</InputLabel>
                                <Field className="input-fiels-create" type="text" id="subject" name="subject" placeholder="Type here" />
                                <ErrorMessage name="subject" component="div" className="error-message" data-test-id="Subject-error-message" />
                              </Grid>

                              <Grid item xs={12}>
                                <InputLabel className="main-input-label" data-test-id="Subject">Course*</InputLabel>
                                <Box className="select-course-category" id="1">
                                  <Field id="select-course-category" as="select" name="course_id" data-test-id="select-course-category">
                                    <option disabled value="" className="text-capitalize" id="2">Select Course</option>
                                    {this.state.courseList.map((item: Course, index: number) => {
                                      return (<option id={`hello-${index}`} key={`categorylistselect${index}`} className="text-capitalize" data-test-id={`categorylistselect${index}`} value={item.id}>{item.attributes.course_name}</option>);
                                    })}
                                  </Field>
                                </Box>
                                <ErrorMessage data-test-id="course_id" name="course_id" component="div" className="error-message" />
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <InputLabel className="main-input-label" data-test-id="Date">Date*</InputLabel>
                                <Box className="position-reletive">
                                  <Field className="input-fiels-create" type="date" id="date" name="date" placeholder="20-08-1990" data-test-id="inputFirstData" onClick={(e:any)=>e.target.showPicker()}/>
                                  <Field className="input-fiels-create-upper" type="date" id="date" name="date" placeholder="20-08-1990" data-test-id="inputSecondData" onClick={(e:any)=>e.target.showPicker()}/>
                                </Box>
                                <ErrorMessage name="date" component="div" className="error-message" data-test-id="Date-error-message" />
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <InputLabel className="main-input-label" data-test-id="Time">Time*</InputLabel>

                                <Box className="time-field">
                                  <Box component={"div"} className="position-reletive w-100">
                                    <Field className="input-fiels-create" data-test-id="startTime" type="time" id="startTime" name="startTime" placeholder="Type here" />
                                    <Field className="input-fiels-create-upper-time" data-test-id="startTime" type="time" id="startTime" name="startTime" placeholder="Type here" />
                                  </Box>
                                  <Typography>to</Typography>
                                  <Box component={"div"} className="position-reletive w-100">
                                    <Field className="input-fiels-create" data-test-id="endTime" type="time" id="endTime" name="endTime" placeholder="Type here" />
                                    <Field className="input-fiels-create-upper-time" data-test-id="endTime" type="time" id="endTime" name="endTime" placeholder="Type here" />
                                  </Box>
                                </Box>

                                <Box className="time-field-error-message" data-test-id="time-field-error-message">
                                  <Box className="width-100" data-test-id="width-100">
                                    <ErrorMessage name="startTime" component="div" className="error-message" data-test-id="startTime-error-message" />
                                  </Box>
                                  <Box className="width-100">

                                    <ErrorMessage name="endTime" component="div" className="error-message" data-test-id="endTime-error-message" />
                                  </Box>
                                </Box>
                              </Grid>

                              <Grid item xs={12}>
                                <Box className="switch-main-contianer" data-test-id="switch-main-contianer">
                                  <InputLabel className="main-input-label margin-0" data-test-id="Show-Live-Location">Show Live Location Address</InputLabel>
                                  <Box className="switch-main-contant" data-test-id="switch-main-contant">
                                    <InputLabel className="main-input-label margin-0" data-test-id="Show-to-student">Show to student</InputLabel>
                                    <AntSwitch
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('showtostudent', event.target.checked)}
                                      checked={values.showtostudent}
                                      data-test-id="showtostudent-change"
                                    />
                                  </Box>
                                </Box>
                                <Field rows="4" className="input-fiels-create-textarea" cols="50" as="textarea" id="livelocation" name="livelocation" placeholder="Type here" data-test-id="livelocation" />
                                <ErrorMessage name="livelocation" component="div" className="error-message" data-test-id="livelocation-error-message" />
                              </Grid>

                            </Grid>
                          </Box>
                        </Box>

                        <Box className="marginbottom">
                          <Grid container justifyContent="flex-end" spacing={3}>
                            <Grid item>
                              <Button type="button" className="back-course-lesson-btn" data-test-id="go-back-save-session" onClick={() => this.onClickGoBackRedirect()}>
                                Back
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button type="submit" data-test-id="save-session" className="save-course-lesson-btn" disabled={this.state.submitFlag}>
                                Save Session
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </form>
                  )
                }}

              </Formik>
            </Paper>
          </MainContainer>
        </NavigationMenu>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  "& .container-main": {
    height: 'calc(100vh - 110px)',
    padding: '40px',
    background: '#F6F8FA',
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 4
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: 2,
      background: "#D7E0E5"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#37657F"
    }
  },
  "& .select-course-category":{
    background:"#FFFFFF",
    borderRadius: 8
  },
  "& #select-course-category":{
    appearance: "none",
    background: `url("${downimage}") no-repeat 99% center`
  },
  "& .switch-main-contant":{display:"flex", justifyContent:"space-between",alignItems:"center",gap:10},
  "& .time-field":{display:"flex", alignItems:"center", gap:6,
  "@media (max-width: 520px)": {
    flexDirection: 'column',
  }},
  "& .time-field-error-message":{display:"flex", alignItems:"center", gap:30, justifyContent:"space-between"},
  "& .width-100":{
    width:"100%"
  },
  "& .switch-main-contianer":{display:"flex", justifyContent:"space-between",alignItems:"center", marginBottom: 16},
  "& .margin-0":{
    margin:0
  },
  
  "& .from-first-container": {
    background: "#E2E8F0",
    borderRadius: 8,
    padding: 40
  },
  "& .from-first-container-grid": {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  "& .image-container-main": {
    display: "flex",
    alignItems: 'center'
  },
  "& .input-fiels-create-upper":{padding:0,border:"1px solid #CBD5E1",background:"#FFFFFF",fontFamily:"Corbel V2",minWidth:"auto",position:"absolute",top:0,opacity:0,width:'87%',right:0,"@media (max-width: 325px)":{width:'40%',},"@media (max-width: 1440px)":{width:'80%',},"@media (max-width: 1350px)":{width:'78%',},"@media (max-width: 1250px)":{width:'75%',},"@media (max-width: 1150px)":{width:'73%',},"@media (max-width: 1050px)":{width:'69%',},"@media (max-width: 950px)":{width:'81%',},"@media (max-width: 850px)":{width:'77%',},"@media (max-width: 800px)":{width:'74%',},"@media (max-width: 767px)":{width:'84%',},"@media (max-width: 667px)":{width:'80%',},"@media (max-width: 520px)":{width:'73%',},"@media (max-width: 410px)":{width:'60%',},"@media (max-width: 350px)":{width:'50%',},"&::placeholder":{color:"#334155",fontFamily:"Corbel V2",},"&::-webkit-calendar-picker-indicator":{width:'100%',height:43,margin:0,background:"transparent",}},
  "& .image-container": {
    background: "rgba(0, 0, 0, 0.10)",
    borderRadius: 8,
    marginRight: 16,
    width: 60,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  "& .from-main-title-first": {
    fontFamily: "Corbel V2",
    fontSize: 16,
    fontWeight: 700,
    color: "#000",
    marginBottom: 5
  },
  "& .from-main-title-second": {
    fontFamily: "Corbel V2",
    fontSize: 14,
    fontWeight: 400,
    color: "#000",
  },
  "& .upload-icon-container": {
    padding: 10,
    borderRadius: 6,
    background: "#F8FAFC",
    position: "relative"
  },
  "& .main-input-label": {
    color: "#334155",
    fontFamily: "Corbel V2",
    fontSize: 16,
    fontWeight: 400,
  },
  "& .input-fiels-create": {
    border: "1px solid #CBD5E1",
    background: "#FFFFFF",
    fontFamily: "Corbel V2",
    minWidth:"auto",
    "&::placeholder": {
      color: "#334155",
      fontFamily: "Corbel V2",
    }
  },
  "& .error-message": {
    fontFamily: "Corbel V2",
    color: "#FF0000",
  },
  "& .input-fiels-create-textarea": {
    "&::placeholder": {
      fontFamily: "Corbel V2",
      color: "#334155",
    },
    background: "#FFFFFF",
    fontFamily: "Corbel V2",
    border: "1px solid #CBD5E1",
    width: "100%",
    padding: 10,
    borderRadius: 8,
    resize: "none",
    "&:focus": {
      outline: "none"
    }
  },
  "& .add-lesson-btn, .save-course-lesson-btn": {
    "& svg": {
      marginLeft: 6,
    },
    background: "#37657F",
    color: "#fff",
    width: '180px',
    height: '50px',
    textTransform: 'none',
    fontFamily: "Corbel V2",
    fontWeight: 700,
    borderRadius:8,
    "& span": {
      fontSize: 16,
    }
  },
  "& .back-course-lesson-btn": {
    "& span": {
      fontSize: 16,
    },
    background: "transparent",
    color: "#37657F",
    width: '180px',
    height: '50px',
    textTransform: 'none',
    fontFamily: "Corbel V2",
    fontSize: 16,
    fontWeight: 700,
    border: "1px solid #37657F",
    borderRadius:8,
  },
  "& .courseImage": {
    maxHeight: 37,
    maxWidth: 37,
    minWidth: 37,
    minHeight: 37,
    top: 0,
    left: 0,
    opacity: 0,
    position: 'absolute',
  },
  "& .marginbottom":{
    marginBottom:20
  },
  "& .paper-container": {
    "& form": {
      margin: 0,
    },
    width: "100%",
    overflow: "hidden",
  },
  "& .from-main-title": {
    color: "#000",
    fontFamily: "Corbel V2",
    marginBottom: 10,
    fontSize: 18,
    fontWeight: 700,
  },
  "& .position-reletive":{
    position:"relative"
  },
  "& .w-100":{
    width:"100%"
  },
  "& .input-fiels-create-upper-time":{padding:0,border:"1px solid #CBD5E1",background:"#FFFFFF",fontFamily:"Corbel V2",minWidth:"auto",position:"absolute",top:0,opacity:0,width:'83%',right:0,"@media (max-width: 1350px)":{width:'70%',},"@media (max-width: 1250px)":{width:'65%',},"@media (max-width: 1150px)":{width:'63%',},"@media (max-width: 1050px)":{width:'58%',},"@media (max-width: 950px)":{width:'76%',},"@media (max-width: 850px)":{width:'70%',},"@media (max-width: 800px)":{width:'66%',},"@media (max-width: 767px)":{width:'80%',},"@media (max-width: 667px)":{width:'75%',},"@media (max-width: 520px)":{width:'80%',},"@media (max-width: 410px)":{width:'75%',},"@media (max-width: 350px)":{width:'70%',},"@media (max-width: 325px)":{width:'65%',},"@media (max-width: 1440px)":{width:'72%',},"&::placeholder":{fontFamily:"Corbel V2",color:"#334155",},"&::-webkit-calendar-picker-indicator":{margin:0,background:"transparent",height:48,width:'100%',}},
})

const AntSwitch = withStyles({
  root: {
    width: 20,
    height: 10,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: "#fff",
    '&$checked': {
      transform: 'translateX(10px)',
      color: "#fff",
      '& + $track': {
        opacity: 1,
        backgroundColor: "#37657F",
        borderColor: "transparent",
      },
    },
  },
  thumb: {
    width: 6,
    height: 6,
    boxShadow: 'none',
  },
  track: {
    border: "1px solid transparent",
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#94A3B8",
  },
  checked: {},
})(Switch);
// Customizable Area End