import React from "react";

// Customizable Area Start
import { Box, Button, Dialog, Grid, IconButton, InputLabel, LinearProgress, Paper, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import CreateCourseController, {
  Props,
  courseValidationSchema
} from "./CreateCourseController";
import { ErrorMessage, Field, Formik } from "formik";
import Alert from "@material-ui/lab/Alert"
import { downimage } from "./assets";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const validationSchema = courseValidationSchema;
// Customizable Area End

export default class CreateCourse extends CreateCourseController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  fileUploadModalVisible = (setFieldValue: any, valuesLessonn: any) => {
    const Video = "video/mp4,.mp4,video/avi,.avi,video/mpeg,.mpeg,.mpg,video/3gpp,.3gp,.divx,video/x-flv,.flv,video/x-matroska,.mkv,video/quicktime,.mov,audio/ogg,.ogg,video/webm,.webm,video/x-ms-wmv,.wmv"
    const Image = "image/*, .pdf, .pptx, .doc, .docx, text/plain, text/csv"
    return (
      <Dialog maxWidth="lg" fullWidth open={this.state.uploadFileModalVisible}>
        <ModalStyle className="uploadModalBlock">
          <Box className="modalDialog">
            <Box className="modal-content">
              <Box className="modalHeader">
                <Box className="blockHeading">
                  <h3>Upload {this.state.modelTitle}</h3>
                  <p>Upload the Lesson videos from your file manager to show facial recognition.</p>
                </Box>
                <Button id='closeFileUploadModalVisible' data-test-id="close-File-Upload-Modal-Visible" onClick={() => { this.ModalClose() }} type="button" className="closeBtn">
                  <span>×</span>
                </Button>
              </Box>
              <Box className="modalBody">
                <Box className="dargBlock">
                  <Box className="uploadBtnWrapper"
                    onClick={this.handleClick}
                  >
                    <button
                      className="dropButton"
                      onDrop={this.handleDrop}
                      onDragOver={this.handleDragOver}
                      onDragEnter={this.handleDragEnter}
                      onDragLeave={this.handleDragLeave}
                    >
                      {this.state.fileData && this.state.modelTitle !== "Lesson Video" ? (
                        <img data-test-id="image-first" src={URL.createObjectURL(this.state.fileData)} />
                      ) : (
                        <>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z" fill="#1C1B1F" />
                          </svg>
                          Click to upload or drag &amp; drop<Typography className="sizeText">Maximum file size 100 MB.</Typography>
                        </>
                      )}
                    </button>
                    <input type="file" title="" className='image-uploaded-box' value={this.state.filesUploadFlag} accept={this.state.modelTitle === "Lesson Video" ? Video : Image} name="myfile" data-test-id="profileImg-second" onChange={(event: any) => {
                      this.handleFileChangeProfile(event)
                    }} />
                  </Box>
                </Box>
                {this.state.open ?
                  <Alert action={
                    <IconButton
                      test-id="discard_profile_modal_submit"
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        this.setState({ open: false })
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                    severity="error">
                    Sorry, an error has occurred while uploading "Raffles Video". Please try again.
                  </Alert> : null
                }
                {this.state.fileData && (
                  <Box className="process-block">
                    <LinearProgress variant="determinate" value={this.state.progressUpload} className='process-bar-clr' />
                    <Box className="process-info">
                      <Box className="process-info-image">
                        {this.state.modelTitle === "Lesson Video" ? (
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6.92773 6.82172V17.1817C6.92773 17.9717 7.79773 18.4517 8.46773 18.0217L16.6077 12.8417C17.2277 12.4517 17.2277 11.5517 16.6077 11.1517L8.46773 5.98172C7.79773 5.55172 6.92773 6.03172 6.92773 6.82172Z" fill="#37657F" />
                          </svg>
                        ) : (
                          <img src={URL.createObjectURL(this.state.fileData)} />
                        )}
                      </Box>
                      <Box className="process-info-content">
                        <h6>{this.state.fileData.name}</h6>
                        <span>{this.state.progressUpload}% uploaded</span>
                      </Box>
                      <Box id='processBlockClose' data-test-id="processBlockClose" onClick={() => { this.setState({ imagesProfile: {}, fileData: null, filesUploadFlag:"" }) }} className="process-block-close">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="modalFooter">
              <Button id='discardId' data-test-id="discardId" onClick={() => { this.ModalClose() }} type="button" variant="outlined" className="discardBtn">Discard</Button>
              <Button id='discardProfileModalSubmit' type="button" data-test-id="discardProfileModalSubmit" className="uploadBtn" variant="outlined" onClick={() => { this.editProfileDetailsImages(setFieldValue, valuesLessonn) }} disabled={!this.state.fileData}>{"Upload now"}</Button>
            </Box>
          </Box>
        </ModalStyle>
      </Dialog>)
  }

  videoRender = (lesson: any, index: number) => {
    return (
      <Box
        component={"div"}
        className='uploadBtnWrapper'
      >
        <button type="button" className={lesson.lesson_video.name ? "uploadBtn-upload" : 'uploadBtn'} data-test-id="uploadBtn" onClick={() => this.onClickOpenModel("Lesson Video", { name: "lesson_video", index: index })}>

          {lesson.lesson_video.name ? (
            <Box className="image-upload-after-main">
              <Box className="image-upload-after">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M6.92773 6.82172V17.1817C6.92773 17.9717 7.79773 18.4517 8.46773 18.0217L16.6077 12.8417C17.2277 12.4517 17.2277 11.5517 16.6077 11.1517L8.46773 5.98172C7.79773 5.55172 6.92773 6.03172 6.92773 6.82172Z" fill="#37657F" />
                </svg>
              </Box>
              <Box>
                <Typography className="image-upload-maintitle">{lesson.lesson_video.name}</Typography>
                <Typography className="image-upload-size">{  (lesson.lesson_video.size  / (1024 * 1024)).toFixed(2) } MB</Typography>
              </Box>
            </Box>
          ) : (
            <>
              Type Lesson Name
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z"
                  fill="#37657F"
                />
              </svg>
            </>
          )}
        </button>
        <ErrorMessage name={`lessonn[${index}].lesson_video`} component="div" className="error-message" />
      </Box>
    )
  }

  imageRender = (lesson: any, index: number) => {
    return (
      <Box
        component={"div"}
        className='uploadBtnWrapper'
      >
        <button type="button" className={lesson.lesson_image.name ? "uploadBtn-upload" : 'uploadBtn'} data-test-id="uploadBtn-upload" onClick={() => this.onClickOpenModel("Lesson Attachments", { name: "lesson_image", index: index })}>
          {lesson.lesson_image.name ? (
            <Box className="image-upload-after-main">
              <Box className="image-upload-after">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M16 6.74917V17.3292C16 19.4192 14.47 21.2792 12.39 21.4792C10 21.7092 8 19.8392 8 17.4992V5.13917C8 3.82917 8.94 2.63917 10.24 2.50917C11.74 2.35917 13 3.52917 13 4.99917V15.4992C13 16.0492 12.55 16.4992 12 16.4992C11.45 16.4992 11 16.0492 11 15.4992V6.74917C11 6.33917 10.66 5.99917 10.25 5.99917C9.84 5.99917 9.5 6.33917 9.5 6.74917V15.3592C9.5 16.6692 10.44 17.8592 11.74 17.9892C13.24 18.1392 14.5 16.9692 14.5 15.4992V5.16917C14.5 3.07917 12.97 1.21917 10.89 1.01917C8.51 0.789169 6.5 2.65917 6.5 4.99917V17.2692C6.5 20.1392 8.6 22.7092 11.46 22.9792C14.75 23.2792 17.5 20.7192 17.5 17.4992V6.74917C17.5 6.33917 17.16 5.99917 16.75 5.99917C16.34 5.99917 16 6.33917 16 6.74917Z" fill="#37657F" />
                </svg>
              </Box>
              <Box>
                <Typography className="image-upload-maintitle">{lesson.lesson_image.name}</Typography>
                <Typography className="image-upload-size">{  (lesson.lesson_image.size  / (1024 * 1024)).toFixed(2) } MB</Typography>
              </Box>
            </Box>
          ) : (
            <>
              Type Lesson Name
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z"
                  fill="#37657F"
                />
              </svg>
            </>
          )}
        </button>
        <ErrorMessage name={`lessonn[${index}].lesson_image`} component="div" className="error-message" />
      </Box>
    )

  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          title={"Create course"}
        >
          <MainContainer>
            <Paper elevation={0} className="paper-container">
              <Formik
                initialValues={{
                  courseImage: "",
                  course_name:"",
                  title: "",
                  information: "",
                  description: "",
                  price: "",
                  level:"",
                  course_type:"",
                  no_of_lessons:"",
                  total_duration_of_lessons:"",
                  address:"",
                  category_id:"",
                  language:"",
                  lessonn: [
                    {
                      lesson_video: "",
                      lesson_image: "",
                      description: "",
                    }
                  ],
                }}
                onSubmit={(values) => {
                  this.onSubmit(values)
                }}
                validationSchema={validationSchema}
              >
                {({ handleSubmit, setFieldValue, values }: any) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box className="container-main">

                        <Box className="marginbottom">
                          <Typography className="from-main-title">Course Banner/Image</Typography>
                          <Box className="from-first-container">
                            <Grid container className="from-first-container-grid">
                              <Grid item>
                                <Box className="image-container-main">
                                  <Box className="image-container">
                                    {values.courseImage ? (
                                      <img src={URL.createObjectURL(values.courseImage)} />
                                    ) : (
                                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path d="M8.33334 35C7.41667 35 6.63167 34.6733 5.97834 34.02C5.325 33.3667 4.99889 32.5822 5 31.6667V8.33334C5 7.41667 5.32667 6.63167 5.98 5.97834C6.63334 5.325 7.41778 4.99889 8.33334 5H31.6667C32.5833 5 33.3683 5.32667 34.0217 5.98C34.675 6.63334 35.0011 7.41778 35 8.33334V31.6667C35 32.5833 34.6733 33.3683 34.02 34.0217C33.3667 34.675 32.5822 35.0011 31.6667 35H8.33334ZM10 28.3333H30L23.75 20L18.75 26.6667L15 21.6667L10 28.3333Z" fill="white" />
                                      </svg>
                                    )}
                                  </Box>
                                  <Box>
                                    <Typography className="from-main-title-first">{values.title ? values.title : "Title"}</Typography>
                                    <Typography className="from-main-title-second">{this.state.categoryName ? this.state.categoryName : "Course Category"}</Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item>
                                <Box className="upload-icon-container">
                                  <input
                                    className="courseImage"
                                    accept="image/*"
                                    type="file"
                                    name="courseImage"
                                    id="courseImage"
                                    data-test-id="uploadField"
                                    onChange={(event: any) => {
                                      setFieldValue(
                                        "courseImage",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                    title=""
                                  />
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8042 15.0208 15.4125 15.4125C15.0208 15.8042 14.55 16 14 16H2ZM7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7Z" fill="#37657F" />
                                  </svg>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <ErrorMessage name="courseImage" component="div" className="error-message" />
                        </Box>


                        <Box className="marginbottom">
                          <Typography className="from-main-title">Information</Typography>
                          <Box className="from-first-container">
                            <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel className="main-input-label">Course Name*</InputLabel>
                                <Field className="input-fiels-create" type="text" id="course_name" name="course_name" placeholder="Type here" />
                                <ErrorMessage name="course_name" component="div" className="error-message" />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel className="main-input-label">Title*</InputLabel>
                                <Field className="input-fiels-create" type="text" id="title" name="title" placeholder="Type here" />
                                <ErrorMessage name="title" component="div" className="error-message" />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel className="main-input-label">Information*</InputLabel>
                                <Field className="input-fiels-create" type="text" id="information" name="information" placeholder="Type here" />
                                <ErrorMessage name="information" component="div" className="error-message" />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel className="main-input-label">Course Category*</InputLabel>
                                <Box className="select-course-category">
                                  <Field data-test-id="select-course-category" id="select-course-category" as="select" name="category_id" 
                                  onChange={(event:any) => {
                                    const value = event.target.value 
                                    setFieldValue("category_id",value)
                                    this.onChangeCourseSelect(value)
                                  }
                                  }>
                                    <option className="text-capitalize" disabled value="">Select Course Category</option>
                                  {this.state.categoriesList.map((item: any,index:number) => {
                                    return (<option key={`categorylistselect${index}`} className="text-capitalize" data-test-id={`categorylistselect${index}`} value={item.attributes.id}>{item.attributes.name}</option>);
                                  })}
                                </Field>
                                </Box>
                                <ErrorMessage name="category_id" component="div" className="error-message" />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel className="main-input-label">Language*</InputLabel>
                                <Box className="select-course-category">
                                  <Field data-test-id="selectgender" id="select-course-category" as="select" name="language" >
                                    <option className="text-capitalize" value="" disabled>Select Language</option>
                                    <option className="text-capitalize" value="English">English</option>
                                    <option className="text-capitalize" value="Mandarin">Mandarin</option>
                                    <option className="text-capitalize" value="Hindi">Hindi</option>
                                    <option className="text-capitalize" value="German">German</option>
                                    <option className="text-capitalize" value="Italian">Italian</option>
                                </Field>
                                </Box>
                                <ErrorMessage name="language" component="div" className="error-message" />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel className="main-input-label">Description*</InputLabel>
                                <Field rows="4" className="input-fiels-create-textarea" cols="50" as="textarea" id="description" name="description" placeholder="Placeholder" />
                                <ErrorMessage name="description" component="div" className="error-message" />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel className="main-input-label">Price*</InputLabel>
                                <Box className="price-field">
                                  <Field className="input-fiels-create" type="number" id="price" name="price" placeholder="Type Price" />
                                  <Box className="price-icon">
                                  د.إ
                                  </Box>
                                </Box>
                                <ErrorMessage name="price" component="div" className="error-message" />
                              </Grid>
                              <Grid item xs={12}>
                              <InputLabel className="main-input-label">Level*</InputLabel>
                                <Box className="select-course-category">
                                  <Field data-test-id="selectgender" id="select-course-category" as="select" name="level" >
                                    <option className="text-capitalize" value="" disabled>Select Level</option>
                                    <option className="text-capitalize" value="beginner">Beginner</option>
                                    <option className="text-capitalize" value="intermediate">Intermediate</option>
                                    <option className="text-capitalize" value="advanced">Advanced</option>
                                </Field>
                                </Box>
                                <ErrorMessage name="level" component="div" className="error-message" />
                              </Grid>
                              <Grid item xs={12}>
                              <InputLabel className="main-input-label">Course Type*</InputLabel>
                                <Box className="select-course-category">
                                  <Field data-test-id="selectgender" id="select-course-category" as="select" name="course_type" >
                                    <option className="text-capitalize" value="" disabled>Select Level</option>
                                    <option className="text-capitalize" value="live_session">Live session</option>
                                    <option className="text-capitalize" value="online_courses">Online courses</option>
                                    <option className="text-capitalize" value="on_campus_workshop">On campus workshop</option>
                                </Field>
                                </Box>
                                <ErrorMessage name="course_type" component="div" className="error-message" />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel className="main-input-label">No of Lessons*</InputLabel>
                                <Field className="input-fiels-create" type="number" id="no_of_lessons" name="no_of_lessons" placeholder="Type" />
                                <ErrorMessage name="no_of_lessons" component="div" className="error-message" />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel className="main-input-label">Total Duration of Lessons*</InputLabel>
                                <Field className="input-fiels-create" type="number" id="total_duration_of_lessons" name="total_duration_of_lessons" placeholder="Type" />
                                <ErrorMessage name="total_duration_of_lessons" component="div" className="error-message" />
                              </Grid>

                              <Grid item xs={12}>
                                <InputLabel className="main-input-label">Address*</InputLabel>
                                <Field className="input-fiels-create" type="text" id="information" name="address" placeholder="Type Address here" />
                                <ErrorMessage name="information" component="div" className="error-message" />
                              </Grid>

                            </Grid>
                          </Box>
                        </Box>

                        {values.lessonn.map((lesson: any, index: number) => (
                          <Box className="marginbottom-40" key={index}>
                            <Typography className="from-main-title">Lessons</Typography>
                            <Box className="from-first-container">
                              <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item xs={12} md={6}>
                                  <InputLabel className="main-input-label">Lesson Video</InputLabel>
                                  {this.videoRender(lesson, index)}
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <InputLabel className="main-input-label">Lesson Attachments</InputLabel>
                                  {this.imageRender(lesson, index)}
                                </Grid>

                                <Grid item xs={12}>
                                  <InputLabel className="main-input-label">Lesson Description*</InputLabel>
                                  <Field rows="4" className="input-fiels-create-textarea" cols="50" as="textarea" id={`description_${index}`} name={`lessonn[${index}].description`} placeholder="Placeholder" />
                                  <ErrorMessage name={`lessonn[${index}].description`} component="div" className="error-message" />
                                </Grid>

                                <Grid item>
                                  <Button className="remove-btn" data-test-id="remove-btn" disabled={values.lessonn.length >= 2 ? false : true} type="button" onClick={() => this.handleRemoveLesson(setFieldValue, values, index, lesson.id)}>
                                    Remove
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path fillRule="evenodd" clipRule="evenodd" d="M14.79 3.29L15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9Z" fill="#DC2626" />
                                    </svg>
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        ))}

                        {this.fileUploadModalVisible(setFieldValue, values.lessonn)}
                        <Box className="marginbottom-40">
                          <Grid container justifyContent="flex-end">
                            <Grid item>
                              <Button className="add-lesson-btn" data-test-id="add-lesson-btn" onClick={() => this.handleAddLesson(setFieldValue, values)}>
                                Add Lesson
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="white" />
                                </svg>
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box className="marginbottom">
                          <Grid container justifyContent="flex-end" spacing={3}>
                            <Grid item>
                              <Button className="back-course-lesson-btn" data-test-id="go-back-save-course" onClick={() => this.onClickGoBack()}>
                                Back
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button type="submit" data-test-id="save-course" className="save-course-lesson-btn" disabled={this.state.submitFlag}>
                                Upload Course
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </form>
                  )
                }}

              </Formik>
            </Paper>
          </MainContainer>
        </NavigationMenu>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  "& .paper-container": {
    width: "100%",
    overflow: "hidden",
    "& form": {
      margin: 0,
    }
  },
  "& .container-main": {
    height: 'calc(100vh - 110px)',
    padding: '40px',
    background: '#F6F8FA',
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 4
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: 2,
      background: "#D7E0E5"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#37657F"
    }
  },
  "& .from-main-title": {
    color: "#000",
    fontFamily: "Corbel V2",
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 10
  },
  "& .from-first-container": {
    background: "#E2E8F0",
    borderRadius: 8,
    padding: 40
  },
  "& .from-first-container-grid": {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  "& .image-container-main": {
    display: "flex",
    alignItems: 'center'
  },
  "& .image-container": {
    background: "rgba(0, 0, 0, 0.10)",
    borderRadius: 8,
    marginRight: 16,
    width: 60,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  "& .from-main-title-first": {
    color: "#000",
    fontFamily: "Corbel V2",
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 5
  },
  "& .from-main-title-second": {
    color: "#000",
    fontFamily: "Corbel V2",
    fontSize: 14,
    fontWeight: 400
  },
  "& .upload-icon-container": {
    background: "#F8FAFC",
    padding: 10,
    borderRadius: 6,
    position: "relative"
  },
  "& .main-input-label": {
    fontFamily: "Corbel V2",
    fontSize: 16,
    fontWeight: 400,
    color: "#334155"
  },
  "& .input-fiels-create": {
    background: "#FFFFFF",
    fontFamily: "Corbel V2",
    border: "1px solid #CBD5E1",
    "&::placeholder": {
      fontFamily: "Corbel V2",
      color: "#334155"
    }
  },
  "& .error-message": {
    color: "#FF0000",
    fontFamily: "Corbel V2",
  },
  "& .input-fiels-create-textarea": {
    background: "#FFFFFF",
    fontFamily: "Corbel V2",
    border: "1px solid #CBD5E1",
    width: "100%",
    padding: 10,
    borderRadius: 8,
    resize: "none",
    "&::placeholder": {
      fontFamily: "Corbel V2",
      color: "#334155"
    },
    "&:focus": {
      outline: "none"
    }
  },
  "& .price-field": {
    position: "relative"
  },
  "& .price-icon": {
    position: 'absolute',
    top: 13,
    right: 50
  },
  "& .uploadBtnWrapper": {
    position: "relative",
    overflow: "hidden",
    display: "block",
    "& input[type=file]": {
      position: "absolute",
      left: 0,
      top: 0,
      opacity: 0,
      cursor: "pointer"
    },
    "& input": {
      position: "absolute",
      left: 0,
      top: 0,
      opacity: 0
    }
  },
  "& .uploadBtn": {
    padding: 10,
    borderRadius: 5,
    border: "1px solid rgba(148,163,184,.5)",
    color: "#6c7487",
    width: "100%",
    textAlign: "left",
    background: "#FFFFFF",
    fontFamily: "Corbel V2",
    minHeight: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 14
  },
  "& .uploadBtn-upload": {
    padding: 10,
    borderRadius: 5,
    color: "#6c7487",
    width: "100%",
    textAlign: "left",
    background: "#FFFFFF",
    fontFamily: "Corbel V2",
    minHeight: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 14,
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    border: 'none'
  },
  "& .add-lesson-btn, .save-course-lesson-btn": {
    background: "#37657F",
    color: "#fff",
    padding: "10px 16px",
    borderRadius: 8,
    textTransform: 'none',
    fontFamily: "Corbel V2",
    fontWeight: 700,
    "& svg": {
      marginLeft: 6,
    },
    "& span": {
      fontSize: 16,
    }
  },
  "& .save-course-lesson-btn": {
    padding: "10px 50px",
    borderRadius: 8,
  },
  "& .back-course-lesson-btn": {
    background: "transparent",
    color: "#37657F",
    padding: "8px 70px",
    textTransform: 'none',
    fontFamily: "Corbel V2",
    fontSize: 16,
    fontWeight: 700,
    width: 180,
    borderRadius: 8,
    border: "1px solid #37657F",
    "& span": {
      fontSize: 16,
    }
  },
  "& .remove-btn": {
    background: "transparent",
    color: "#DC2626",
    padding: "8px 16px",
    textTransform: 'none',
    fontFamily: "Corbel V2",
    fontWeight: 700,
    border: "1px solid #DC2626",
    borderRadius: 8,
    "& svg": {
      marginLeft: 6,
    },
    "& span": {
      fontSize: 16,
    }
  },
  "& .courseImage": {
    maxWidth: 37,
    minWidth: 37,
    minHeight: 37,
    maxHeight: 37,
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0
  },
  "& .image-upload-after": {
    height: 55,
    width: 55,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: "#F1F5F9"
  },
  "& .image-upload-after-main": {
    display: "flex", alignItems: "center", gap: 20
  },
  "& .image-upload-maintitle": {
    fontFamily: "Corbel V2",
    fontWeight: 400,
    color: "#0F172A",
    fontSize: 16,
  },
  "& .image-upload-size": {
    fontFamily: "Corbel V2",
    fontWeight: 400,
    color: "#37657F",
    fontSize: 16,
  },
  "& .marginbottom":{
    marginBottom:20
  },
  "& .marginbottom-40":{
    marginBottom:40
  },
  "& .select-course-category":{
    background:"#FFFFFF",
    borderRadius: 8
  },
  "& #select-course-category":{
    appearance: "none",
    background: `url("${downimage}") no-repeat 99% center`
  }
})

const ModalStyle = styled(Box)({
  zIndex: 7,
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  "& .MuiAlert-root":{
    marginTop:20,
    fontFamily: "Corbel V2"
  },
  "& .closeBtn": {
    background: "rgba(217, 217, 217, 0.50)",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
    padding: "0",
    width: "40px",
    minWidth: "40px",
    height: "40px",
    lineHeight: "35px",
    color: "#1C1B1F",
    borderRadius: "6px",
  },
  "& .modalDialog": {
    padding: "40px",
    maxWidth: "1280px",
    margin: "0 auto",
    background: "#fff",
    borderRadius: "10px",
    "@media (max-width: 1366px)": {
      maxWidth: '1000px'
    },
    "@media (max-width: 1199px)": {
      maxWidth: '850px'
    },
    "@media (max-width: 991px)": {
      maxWidth: '650px'
    }

  },
  "& .modalHeader": {
    padding: '0px',
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    "& span": {
      fontSize: '32px'
    }
  },
  "& .blockHeading": {
    padding: '0px',
    "& h3": {
      letterSpacing: "-0.198px",
      margin: "0 0 5px",
      color: "#334155",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      display: "flex",
      alignItems: "center",
    },
    "& p": {
      color: "#37657F",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      paddingTop: "5px",
      margin: "0",
    }
  },
  "& .modalBody": {
    position: "relative",
    padding: "40px 0", 
  },
  "& .modalBody::after": {
    transform: "translate(-50%)",
    content: '""',
    position: "absolute",
    borderBottom: "1px solid #E2E8F0",
    bottom: "-25px",
    left: "50%",
    width: "100%",
  },
  "& .dargBlock": {
    position: "relative",
    border: "1px dashed #94A3B8",
    margin: "0",
    borderRadius: "5px",
    height: "180px",
    textAlign: "center",
    background: "#F8FAFC",
    cursor: "pointer",
  },
  "& .uploadBtnWrapper": {
    borderRadius: 5,
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    width: "100%",
    height: "180px",
    lineHeight: "150px",
  },
  "& .dropButton": {
    border: "none",
    flexDirection: 'column',
    color: "#334155",
    background: "transparent",
    padding: "0",
    textAlign: "center",
    height: "180px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: 'none',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "& .sizeText": {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      color: "#37657F",
      padding: "9px 0 0",
      display: "block",
    },
    "& span": {
      flexDirection: 'column',

    }
  },
  "& .image-uploaded-box":{
    position: 'absolute',
    top: 0,
    height: '180px',
    opacity: 0,
    cursor:"pointer"
  },
  "& .modalFooter": {
    margin: "0",
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    gridGap: "20px",
    gap: "20px",
    padding: "40px 0 0",
    flexWrap: "wrap"
  },
  "& .discardBtn": {
    minWidth: "100px",
    border: "1px solid #94A3B8",
    textTransform: 'none',
    backgroundColor: "#F1F5F9",
    color: "#205e83",
    padding: "12px",
    width: "100%",
    fontWeight: 700,
    lineHeight: "24px",
    maxWidth: "100px",
    fontFamily: "Corbel V2"
  },
  "& .discardBtn:hover": {
    color: "#fff",
    backgroundColor: "#205e83",
  },
  "& .uploadBtn": {
    color: "#fff",
    maxWidth: " 150px",
    minWidth: "150px",
    fontSize: "16px",
    fontFamily: "Corbel V2",
    background: '#205e83',
    textTransform: "none",
    fontWeight: 700,
  },
  "& .display-none":{
    display:"none"
  }
})

// Customizable Area End

