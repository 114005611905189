import React from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  withStyles,
  FormLabel
} from "@material-ui/core";
import { logo } from "./assets";
import { Close } from "@material-ui/icons";
import LecturerSignUpController, { Props } from "./LecturerSignUpController";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";

const validationSchema = Yup.object({
  firstname: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name/")
    .required("First name is required."),
  lastname: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name.")
    .required("Last name is required."),
  officialemail: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, // Replace with your desired pattern
      "Please enter valid email address."
    )
    .required("Official email is required."),
    countryNumber: Yup.string().min(2,"minumin number length should be 2 i.e. 91").max(4, "maximum number length should be 4 i.e. +971").required('Please enter country code'),
  phonenumber: Yup.string()
    .matches(/^[0-9 ]*$/, "Please enter valid number.")
    .required("Number is required.")
    .min(7, "Mobile number should contain minimum 7 digits."),
  qualification: Yup.string().matches(/^[A-Za-z ]*$/, "Please enter valid Qualification.").required("Qualification is required."),
  personalphoto: Yup.mixed().required("Personal photo is required.").test('fileSize', 'File size is too large.', (value:any) => {
    return value && value.size <= 5 * 1024 * 1024; // 5MB
  }),
  dateofbirth: Yup.string().required("DOB is required."),
  identificationcard: Yup.mixed().required("Identification card is required.").test('fileSize', 'File size is too large.', (value:any) => {
    return value && value.size <= 5 * 1024 * 1024; // 5MB
  }),
  resume: Yup.mixed().required("Resume is required.").test('fileSize', 'File size is too large.', (value:any) => {
    return value && value.size <= 5 * 1024 * 1024; // 5MB
  }),
  department: Yup.string().matches(/^[A-Za-z ]*$/, "Please enter valid department.").required("Department is required."),
  major: Yup.string().matches(/^[A-Za-z ]*$/, "Please enter valid major.").required("Major is required."),
  experience: Yup.string().required("Experience is required."),
  biography: Yup.string().required("Biography is required."),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters.')
    .required('New password is required.'),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match.')
    .required('Confirm password is required.'),
});

export default class LecturerSignUp extends LecturerSignUpController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    const today = new Date().toISOString().split("T")[0];
    return (
      <Box component={"div"} data-test-id="id1" className={classes.mainWrapper}>
        <Grid container className={classes.mainRoot}>
          <Grid item xs={12} md={6}>
            <Box component={"div"} className={classes.mainLogoBox}>
              <Box
                component={"div"}
                data-test-id="id6"
                className={classes.loginImage}
              >
                <img
                  src={logo}
                  width={300}
                  alt="logo"
                  className={classes.formImageImg}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box component={"div"} className={classes.mainLogoBoxSec}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Box
                    component={"div"}
                    className={classes.closeButton}
                    data-test-id="close-button-back"
                    onClick={() =>
                      this.props.navigation.navigate(
                        "EmailAccountLoginBlockWeb"
                      )
                    }
                  >
                    <Close className={classes.blackColor} />
                  </Box>
                </Grid>
              </Grid>
              <Box component={"div"} className={classes.inputBox}>
                <Formik
                  initialValues={{
                    firstname: "",
                    lastname: "",
                    officialemail: "",
                    countryNumber:"",
                    phonenumber: "",
                    qualification: "",
                    personalphoto: null,
                    dateofbirth: "",
                    identificationcard: "",
                    resume: "",
                    department: "",
                    major: "",
                    experience: "",
                    biography: "",
                    password: '',
                    confirmpassword: "",
                  }}
                  onSubmit={values => {
                    this.onClickSubmit(values);
                  }}
                  validationSchema={validationSchema}
                >
                  {({ handleSubmit, values, setFieldValue }: any) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography className={classes.welcomeTitle}>
                              Welcome!
                            </Typography>
                            <Typography className={classes.SignupTitle}>
                              Sign Up for an account
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          className={classes.inputContainer}
                        >
                          <Grid item xs={12} lg={6}>
                            <FormLabel className={classes.labels}>
                              First Name*
                            </FormLabel>
                            <Field
                              type="text"
                              id="firstname"
                              data-test-id="firstname"
                              name="firstname"
                              placeholder="First Name"
                            />
                            <ErrorMessage
                              name="firstname"
                              component="div"
                              className={classes.errorMessage}
                            />
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <FormLabel className={classes.labels}>
                              Last Name*
                            </FormLabel>
                            <Field
                              type="text"
                              id="lastname"
                              name="lastname"
                              placeholder="Last Name"
                            />
                            <ErrorMessage
                              name="lastname"
                              component="div"
                              className={classes.errorMessage}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel className={classes.labels}>
                              Official Email*
                            </FormLabel>
                            <Field
                              type="email"
                              id="officialemail"
                              name="officialemail"
                              placeholder="Raffles@.com"
                            />
                            <ErrorMessage
                              name="officialemail"
                              component="div"
                              className={classes.errorMessage}
                            />
                          </Grid>

<Grid container className={classes.phoneContainer} >
                            <Grid item xs={12} lg={3}  className={classes.phoneCode}>                       
                              <FormLabel className={classes.labels}>
                              Code*
                            </FormLabel>
                              <Field
                                type="text"
                                id="countryNumber"
                                name="countryNumber"
                                placeholder="+971"
                                className={classes.phoneCodeInput}
                              />
                              <span>
                                <ErrorMessage name="countryNumber" component="div" className="error" />
                              </span>
                            </Grid>

                            <Grid item xs={12} lg={8} className="">
                             <FormLabel className={classes.labels}>
                              Phone Number*
                            </FormLabel>
                            <Field
                              type="text"
                              id="phonenumber"
                              name="phonenumber"
                              placeholder="93 9875457898"
                            />
                            <ErrorMessage
                              name="phonenumber"
                              component="div"
                              className={classes.errorMessage}
                            />
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                              <FormLabel className={classes.labels}>
                              Password*
                            </FormLabel>
                            <Box className={classes.passwordField}>
                            <Field
                              type={`${this.state.inputVisible === false ? "password" : "text"}`}
                              id="password"
                              name="password"
                              placeholder="********"
                            />
                            <span className={classes.password} >
                              {!this.state.inputVisible ? (
                                <svg data-test-id="show-first" onClick={() => this.setState({ inputVisible: true })}  xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" /></svg>
                              ) : (
                                <svg data-test-id="hide-first" onClick={() => this.setState({ inputVisible: false })}  xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>
                              )}
                            </span>
                            </Box>
                            <ErrorMessage
                              name="password"
                              component="div"
                              className={classes.errorMessage}
                            />
                            
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel className={classes.labels}>
                              Confirm Password*
                            </FormLabel>
                            <Field
                              type="password"
                              id="confirmpassword"
                              name="confirmpassword"
                              placeholder="********"
                            />
                            <ErrorMessage
                              name="confirmpassword"
                              component="div"
                              className={classes.errorMessage}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel className={classes.labels}>
                              Qualification*
                            </FormLabel>
                            <Field
                              type="text"
                              id="qualification"
                              data-test-id="qualification"
                              name="qualification"
                              placeholder="Type here"
                            />
                            <ErrorMessage
                              name="qualification"
                              component="div"
                              className={classes.errorMessage}
                            />
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <FormLabel className={classes.labels}>
                              Personal Photo
                            </FormLabel>
                            <Box
                              component={"div"}
                              className={classes.uploadBtnWrapper}
                            >
                              <button className={classes.uploadBtn}>
                                {values.personalphoto
                                  ? values.personalphoto.name
                                  : "Upload here"}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z"
                                    fill="#37657F"
                                  />
                                </svg>
                              </button>
                              <input
                                accept="image/*"
                                type="file"
                                name="personalphoto"
                                data-test-id="personalphoto-test"
                                onChange={(event: any) => {
                                  setFieldValue(
                                    "personalphoto",
                                    event.currentTarget.files[0]
                                  );
                                  this.handleFileProfilePhoto(event)
                                }}
                              />
                              <ErrorMessage
                                name="personalphoto"
                                component="div"
                                className={classes.errorMessage}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <FormLabel className={classes.labels}>
                              Date of birth*
                            </FormLabel>
                            <Field
                              type="date"
                              id="dateofbirth"
                              name="dateofbirth"
                              placeholder="20-08-1990"
                              max={today}
                              pattern="\d{2}/\d{2}/\d{4}"
                            />
                            <ErrorMessage
                              name="dateofbirth"
                              component="div"
                              className={classes.errorMessage}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel className={classes.labels}>
                              Identification Card*{" "}
                              <span className={classes.blockContentSpan}>
                                (Passport no., National &amp; Resident ID -
                                lqama)*
                              </span>
                            </FormLabel>
                            <Box
                              component={"div"}
                              className={classes.uploadBtnWrapper}
                            >
                              <button className={classes.uploadBtn}>
                                {values.identificationcard
                                  ? values.identificationcard.name
                                  : "Upload here"}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z"
                                    fill="#37657F"
                                  />
                                </svg>
                              </button>
                              <input
                                accept="image/*"
                                type="file"
                                name="identificationcard"
                                data-test-id="identificationcard-test"
                                onChange={(event: any) => {
                                  setFieldValue(
                                    "identificationcard",
                                    event.currentTarget.files[0]
                                  );
                                  this.handleFileChangeCardId(event)
                                }}
                              />
                              <ErrorMessage
                                name="identificationcard"
                                component="div"
                                className={classes.errorMessage}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel className={classes.labels}>
                              Resume*
                            </FormLabel>
                            <Box
                              component={"div"}
                              className={classes.uploadBtnWrapper}
                            >
                              <button className={classes.uploadBtn}>
                                {values.resume
                                  ? values.resume.name
                                  : "Upload here"}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z"
                                    fill="#37657F"
                                  />
                                </svg>
                              </button>
                              <input
                                accept="image/*"
                                type="file"
                                name="resume"
                                data-test-id="resume-test"
                                onChange={(event: any) => {
                                  setFieldValue(
                                    "resume",
                                    event.currentTarget.files[0]
                                  );
                                  this.handleFileResume(event)
                                }}
                              />
                              <ErrorMessage
                                name="resume"
                                component="div"
                                className={classes.errorMessage}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <FormLabel className={classes.labels}>
                              Department*
                            </FormLabel>
                            <Field
                              type="text"
                              id="department"
                              data-test-id="department"
                              name="department"
                              placeholder="Department"
                            />
                            <ErrorMessage
                              name="department"
                              component="div"
                              className={classes.errorMessage}
                            />
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <FormLabel className={classes.labels}>
                              Major/s*
                            </FormLabel>
                            <Field
                              type="text"
                              id="major"
                              data-test-id="major"
                              name="major"
                              placeholder="major/s"
                            />
                            <ErrorMessage
                              name="major"
                              component="div"
                              className={classes.errorMessage}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel className={classes.labels}>
                              Experience*
                            </FormLabel>
                            <Field
                              type="text"
                              id="experience"
                              data-test-id="experience"
                              name="experience"
                              placeholder="experience"
                            />
                            <ErrorMessage
                              name="experience"
                              component="div"
                              className={classes.errorMessage}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormLabel className={classes.labels}>
                              Biography*
                            </FormLabel>
                            <Field
                              type="text"
                              id="biography"
                              data-test-id="biography"
                              name="biography"
                              placeholder="Type here"
                            />
                            <ErrorMessage
                              name="biography"
                              component="div"
                              className={classes.errorMessage}
                            />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Button
                              type="submit"
                              data-test-id="submit-lecturer-signup"
                              className={classes.signupButton}
                              fullWidth
                            >
                              Sign Up
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const webStyle = {
  mainRoot: {
    height: "100%",
    maxWidth: 1200
  },
  mainWrapper: {
    padding: "80px",
    justifyContent: "center",
    display: "flex",
    backgroundColor: "rgba(55, 101, 127, 0.2)",
    height: "calc(100vh - 0px)",
    "& .MuiTypography-root, .MuiFormLabel-root": {
      fontFamily: "Corbel V2"
    },
    "@media (max-width: 959px)": {
      padding: "0"
    }
  },
  mainLogoBox: {
    background: "#37657F",
    padding: 60,
    height: "100%",
    borderRadius: "20px 0 0 20px",
    "@media (max-width: 959px)": {
      borderRadius: "20px 20px 0 0"
    }
  },
  mainLogoBoxSec: {
    background: "#FFF",
    padding: 40,
    height: "100%",
    borderRadius: "0 20px 20px 0",
    "@media (max-width: 959px)": {
      borderRadius: "0 0 20px 20px"
    }
  },
  loginImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: "100%",
    backgroundColor: "#fff",
    borderRadius: 14,
    width: "100%",
    maxHeight: "100%",
    overflow: "hidden",
    "@media (max-width: 575px)": {
      minHeight: "100%"
    }
  },
  closeButton: {
    background: "rgba(217, 217, 217, 0.50)",
    width: 35,
    height: 35,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  blackColor: {
    fill: "black"
  },
  inputBox: {
    padding: "20px 50px",
    height: "calc(100vh - 280px)"
  },
  welcomeTitle: {
    color: "#000",
    fontSize: 32,
    fontWeight: 700
  },
  SignupTitle: {
    color: "#000",
    fontSize: 16,
    fontWeight: 400
  },
  inputContainer: {
    marginTop: 37,
    marginBottom: 37,
    height: "calc(100vh - 510px)",
    overflowY: "auto" as const,
    overflowX: "hidden" as const,
    "@media (max-width: 959px)": {
      height: "calc(100vh - 310px)"
    },
    "&::-webkit-scrollbar": {
      width: 4
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: 2,
      background: "#D7E0E5"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#37657F"
    }
  },
  labels: {
    color: "#334155",
    fontSize: 16,
    fontWeight: 400
  },
  errorMessage: {
    color: "#FF0000",
    fontFamily: "Corbel V2"
  },
  uploadBtnWrapper: {
    position: "relative" as const,
    overflow: "hidden",
    display: "block",
    "& input[type=file]": {
      position: "absolute" as const,
      left: 0,
      top: 0,
      opacity: 0,
      cursor: "pointer"
    },
    "& input": {
      position: "absolute" as const,
      left: 0,
      top: 0,
      opacity: 0
    }
  },
  uploadBtn: {
    padding: 10,
    borderRadius: 5,
    border: "1px solid rgba(148,163,184,.5)",
    color: "#6c7487",
    width: "100%",
    textAlign: "left" as const,
    background: "transparent",
    fontFamily: "Corbel V2",
    minHeight: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 14
  },
  blockContentSpan: {
    display: "block",
    color: "rgba(0,0,0,0.6)",
    fontSize: 14,
    fontWeight: 400,
    marginTop:5
  },
  signupButton: {
    textTransform: "capitalize" as const,
    fontFamily: "Corbel V2",
    padding: 13,
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    background: "#37657F",
    color: "#FFF",
    "&:hover": {
      color: "#000",
      border: "2px solid #37657F"
    }
  },
  passwordField:{
    position: 'relative' as const,
  },
  password:{
    position: 'absolute' as const,
    right: 10,
    top: 15,
    fill: '#94A3B8',
    fontSize: 16
  },
  phoneContainer:{justifyContent:"space-between", padding:"12px"},
  phoneCode:{marginBottom:"24px"},
  phoneCodeInput:{minWidth:"0px"}
};
//@ts-ignore
export const LecturerSignUpStyles = withRouter(withStyles(webStyle)(LecturerSignUp));
