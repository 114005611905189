import React, { Component } from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  styled,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Rating from "@material-ui/lab/Rating/Rating";
const baseURL = require("../../framework/src/config.js").baseURL;
// Customizable Area End

interface Props {
  tutorialVideoSrc: string;
  handleSkipBackwardTutorial: (value: number) => void;
  handleSkipForwardTutorial: (value: number) => void;
  isPlaying: boolean;
  isHover: boolean;
  togglePlayPauseTutorial: () => void;
  handleVideoTutorialPlay: () => void;
  videoRef: any;
  handleVideoTutorialPause: () => void;
  onMouseEnterTutorial: () => void;
  onMouseLeaveTutorial: () => void;
  setTutorialDialogOpen: boolean;
  handleCloseTutorial: () => void;
}

export default class TutorialVideoGuest extends Component<Props> {
  render() {
    return (
      <Dialog
        open={this.props.setTutorialDialogOpen}
        aria-labelledby="max-width-dialog-title"
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle
          id="landing-dialog-title-landing"
          style={{ padding: "40px 40px 0", margin: "0 0 25px" }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                style={{
                  color: "#000",
                  fontFamily: "Corbel V2",
                  fontSize: "32px",
                  fontStyle: "normal",
                  fontWeight: "bold" as const,
                  lineHeight: "normal",
                  marginBottom: "8px",
                }}
                variant="h4"
              >
                Tutorials
              </Typography>
              <Typography
                style={{
                  color: "#000",
                  fontFamily: "Corbel V2",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "normal" as const,
                  lineHeight: "normal",
                }}
              >
                How the platform works
              </Typography>
            </Box>
            <Box
              style={{
                position: "absolute" as const,
                cursor: "pointer",
                right: "48px",
                top: "40px",
                background: "rgba(217, 217, 217, 0.50)",
                width: "35px",
                height: "35px",
                textAlign: "center" as const,
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Close
                data-test-id="closeLandingVideoDialog"
                onClick={this.props.handleCloseTutorial}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent
          style={{ padding: "0 40px 40px" }}
          className="tutorialModalContentLanding"
        >
          <Box
            component={"div"}
            style={{ position: "relative" as const, margin: "0 0 40px" }}
          >
            <video
              controls
              src={baseURL + this.props.tutorialVideoSrc}
              ref={this.props.videoRef}
              data-test-id="plaupausevideoLanding"
              className="videoTag"
              onPlay={this.props.handleVideoTutorialPlay}
              onPause={this.props.handleVideoTutorialPause}
            />
            <Box
              component={"div"}
              data-test-id="mouseEnterLanding"
              onMouseEnter={this.props.onMouseEnterTutorial}
              onMouseLeave={this.props.onMouseLeaveTutorial}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                position: "absolute" as const,
                top: 0,
                height: "50%",
                width: "100%",
              }}
            >
              {this.props.isHover && (
                <>
                  <Box
                    component={"div"}
                    style={{ position: "absolute" as const }}
                  >
                    <Box
                      component={"span"}
                      data-test-id="playPause"
                      onClick={this.props.togglePlayPauseTutorial}
                    >
                      {this.props.isPlaying ? (
                        <svg
                          className="pauseButonLanding"
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 512 512"
                        >
                          <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm224-72V328c0 13.3-10.7 24-24 24s-24-10.7-24-24V184c0-13.3 10.7-24 24-24s24 10.7 24 24zm112 0V328c0 13.3-10.7 24-24 24s-24-10.7-24-24V184c0-13.3 10.7-24 24-24s24 10.7 24 24z" />
                        </svg>
                      ) : (
                        <svg
                          className="playButtonLanding"
                          xmlns="http://www.w3.org/2000/svg"
                          width={50}
                          height={50}
                          viewBox="0 0 112 112"
                          fill="none"
                        >
                          <path
                            d="M42.0382 81.1248L81.1215 55.9998L42.0382 30.8748V81.1248ZM55.9965 111.833C48.2729 111.833 41.0145 110.368 34.2215 107.436C27.4284 104.505 21.5194 100.527 16.4944 95.5019C11.4694 90.4769 7.49127 84.5679 4.56002 77.7748C1.62877 70.9818 0.163147 63.7235 0.163147 55.9998C0.163147 48.2762 1.62877 41.0179 4.56002 34.2248C7.49127 27.4318 11.4694 21.5228 16.4944 16.4978C21.5194 11.4728 27.4284 7.49463 34.2215 4.56338C41.0145 1.63213 48.2729 0.166504 55.9965 0.166504C63.7201 0.166504 70.9784 1.63213 77.7715 4.56338C84.5646 7.49463 90.4736 11.4728 95.4986 16.4978C100.524 21.5228 104.502 27.4318 107.433 34.2248C110.364 41.0179 111.83 48.2762 111.83 55.9998C111.83 63.7235 110.364 70.9818 107.433 77.7748C104.502 84.5679 100.524 90.4769 95.4986 95.5019C90.4736 100.527 84.5646 104.505 77.7715 107.436C70.9784 110.368 63.7201 111.833 55.9965 111.833ZM55.9965 100.667C68.4659 100.667 79.0277 96.3394 87.6819 87.6853C96.3361 79.0311 100.663 68.4693 100.663 55.9998C100.663 43.5304 96.3361 32.9686 87.6819 24.3144C79.0277 15.6603 68.4659 11.3332 55.9965 11.3332C43.527 11.3332 32.9652 15.6603 24.3111 24.3144C15.6569 32.9686 11.3298 43.5304 11.3298 55.9998C11.3298 68.4693 15.6569 79.0311 24.3111 87.6853C32.9652 96.3394 43.527 100.667 55.9965 100.667Z"
                            fill="#F8FAFC"
                          />
                        </svg>
                      )}
                    </Box>
                  </Box>
                  <Box component={"div"}>
                    <Box
                      component={"span"}
                      data-test-id="backvideo"
                      onClick={() => this.props.handleSkipBackwardTutorial(10)}
                      className="backIconLanding"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={40}
                        height={40}
                        viewBox="0 0 80 80"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M39.9942 13.8133C56.8275 13.8133 70.4608 27.7133 69.9942 44.6799C69.5608 60.3133 56.4942 73.3799 40.8608 73.8133C23.8942 74.2799 9.99416 60.6466 9.99416 43.8133C9.99416 38.4466 11.4275 33.4466 13.8608 29.0799C14.9275 27.1799 17.5608 26.8799 19.0942 28.4133C20.1608 29.4466 20.4275 31.0466 19.6942 32.3466C17.7942 35.7466 16.6608 39.6466 16.6608 43.8133C16.6608 56.8133 27.2942 67.3133 40.3275 67.1466C52.7275 66.9799 63.1608 56.5466 63.3275 44.1466C63.4942 31.0799 52.9942 20.4799 39.9942 20.4799L39.9942 26.4466C39.9942 27.9466 38.1942 28.6799 37.1608 27.6466L27.8608 18.3466C27.1942 17.6799 27.1942 16.6466 27.8608 15.9799L37.1608 6.67992C38.1942 5.61325 39.9942 6.34658 39.9942 7.84658L39.9942 13.8133ZM46.6608 43.8133C46.6608 47.4799 43.6608 50.4799 39.9942 50.4799C36.3275 50.4799 33.3275 47.4799 33.3275 43.8132C33.3275 40.1466 36.3275 37.1466 39.9942 37.1466C43.6608 37.1466 46.6608 40.1466 46.6608 43.8133Z"
                          fill="#F8FAFC"
                        />
                      </svg>
                    </Box>
                    <Box
                      component={"span"}
                      data-test-id="forwordvideo"
                      onClick={() => this.props.handleSkipForwardTutorial(10)}
                      className="nextIconLanding"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={40}
                        height={40}
                        viewBox="0 0 80 80"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M39.9942 13.8133C56.8275 13.8133 70.4608 27.7133 69.9942 44.6799C69.5608 60.3133 56.4942 73.3799 40.8608 73.8133C23.8942 74.2799 9.99416 60.6466 9.99416 43.8133C9.99416 38.4466 11.4275 33.4466 13.8608 29.0799C14.9275 27.1799 17.5608 26.8799 19.0942 28.4133C20.1608 29.4466 20.4275 31.0466 19.6942 32.3466C17.7942 35.7466 16.6608 39.6466 16.6608 43.8133C16.6608 56.8133 27.2942 67.3133 40.3275 67.1466C52.7275 66.9799 63.1608 56.5466 63.3275 44.1466C63.4942 31.0799 52.9942 20.4799 39.9942 20.4799L39.9942 26.4466C39.9942 27.9466 38.1942 28.6799 37.1608 27.6466L27.8608 18.3466C27.1942 17.6799 27.1942 16.6466 27.8608 15.9799L37.1608 6.67992C38.1942 5.61325 39.9942 6.34658 39.9942 7.84658L39.9942 13.8133ZM46.6608 43.8133C46.6608 47.4799 43.6608 50.4799 39.9942 50.4799C36.3275 50.4799 33.3275 47.4799 33.3275 43.8132C33.3275 40.1466 36.3275 37.1466 39.9942 37.1466C43.6608 37.1466 46.6608 40.1466 46.6608 43.8133Z"
                          fill="#F8FAFC"
                        />
                      </svg>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>

          <Box>
            <Typography
              variant="h4"
              style={{
                color: "#000",
                fontFamily: "Corbel V2",
                fontSize: "32px",
                fontStyle: "normal",
                fontWeight: "bold" as const,
                lineHeight: "normal",
                marginBottom: "8px",
              }}
            >
              Found useful?
            </Typography>
            <Typography
              style={{
                color: "#000",
                fontFamily: "Corbel V2",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "normal" as const,
                lineHeight: "normal",
              }}
            >
              Rate us based on experience
            </Typography>
          </Box>
          <Box className="ratingBlockWrapperLanding">
            <StyledRatingLanding
              disabled
              data-test-id="writereview"
              size="large"
            />
            <Button
              data-test-id="onClickSubmitData"
              style={{
                borderRadius: "8px",
                background: "#37657F",
                padding: "10px 16px",
                color: "#FFF",
                textTransform: "capitalize" as const,
                fontFamily: "Corbel V2",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "bold" as const,
                lineHeight: "24px",
                cursor: "not-allowed",
              }}
            >
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}
const StyledRatingLanding = styled(Rating)({
  "& .MuiSvgIcon-root": {
    background: "#CBD5E1",
    padding: 10,
    borderRadius: 10,
  },
  "& .MuiRating-label": {
    marginBottom: 0,
    "& :last-child": { marginRight: 7 },
  },
  "& .MuiSvgIcon-fontSizeInherit": { marginLeft: 7 },
  "& .MuiRating-iconFilled": { color: "#F59E0B" },
  "& .MuiRating-iconHover": { color: "#F59E0B", cursor: "not-allowed" },
  position: "relative",
  right: 10,
  "& span": { fontSize: 40 },
});
